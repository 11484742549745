// 公司简介
<template>
  <div id="about">
    <div class="main">
      <div class="banner"></div>
      <div class="company_profile floor">
        <div class="company_title">我们是谁</div>
        <div class="company_text">
          九州通30余年的物流技术发展与沉淀，全面对外提供技术服务。集团创建了九州云智公司，定位于供应链物流科技型、平台型企业；专注于物流中心集成、系统平台集成、智能装备集成、物流运营管理一体化的数智物流技术解决方案，致力于采购、生产、销售、逆向环节的全场景供应链物流大集成技术服务，为泛大健康领域的医药器械、酒水饮料、食品生鲜、美妆日化等行业提供了300余个成功案例。
        </div>
        <div class="recommend">
          <div class="recommend_01">
            <div>
              <img src="../assets/image/about/grop-1.png" alt="" />
            </div>
            <div class="recommend_02">30年</div>
            <div class="recommend_03">行业积累</div>
          </div>
          <div class="recommend_01">
            <div>
              <img src="../assets/image/about/grop-2.png" alt="" />
            </div>
            <div class="recommend_02">31家</div>
            <div class="recommend_03">分支机构</div>
          </div>
          <div class="recommend_01">
            <div>
              <img src="../assets/image/about/grop-3.png" alt="" />
            </div>
            <div class="recommend_02">300+</div>
            <div class="recommend_03">技术开发人员</div>
          </div>
          <div class="recommend_01">
            <div>
              <img src="../assets/image/about/grop-4.png" alt="" />
            </div>
            <div class="recommend_02">60余项</div>
            <div class="recommend_03">发明专利&著作</div>
          </div>
          <div class="recommend_01">
            <div>
              <img src="../assets/image/about/grop-5.png" alt="" />
            </div>
            <div class="recommend_02">12个</div>
            <div class="recommend_03">覆盖行业</div>
          </div>
          <div class="recommend_01">
            <div>
              <img src="../assets/image/about/grop-6.png" alt="" />
            </div>
            <div class="recommend_02">300+</div>
            <div class="recommend_03">成功案例</div>
          </div>
        </div>
      </div>
      <div class="dev floor" style="background-color: #858789;">
        <h1>发展历程</h1>
        <div class="k">
          <div class="box" v-for="(data, index) in devData" :key="index" @mousemove="changeIndex(index)"
            :style="{ 'opacity': currentIndex === index ? '1' : '0.7' }">
            <div class="title">{{ data.h1 }} <span class="ricon"><img style="width: 0.3rem;height: 0.3rem;"
                  :src=data.img /></span></div>
            <div class="conten" v-html="data.t7"></div>
          </div>
        </div>

        <div class="p_text">
          <div class="p_bg">
          </div>
          <div class="t_item" :style="index === 0 ? 'margin-left:0' : ''" v-for="(year, index) in devYear" :key="index">
            <img :src="currentIndex === index
              ? require('@/assets/image/about/f3_c2.png')
              : require('@/assets/image/about/f3_c1.png')
              " />
            <div :class="{ color: currentIndex === index }" class="color" @mousemove="changeIndex(index)">
              {{ year }}
            </div>
          </div>
        </div>
        <div id="car">
          <img src="~assets/image/about/f3_car_1.png" alt="" />
        </div>
      </div>
      <div style="height: 3.96rem;padding-top: 0.7rem;" class="tn">
        <div class="tn-1">管理体系</div>
        <div class="recommend-t" style="display: flex;padding: 0 4.69rem;">
          <div class="recommend_01_t">
            <div>
              <img src="../assets/image/about/card-1.png" alt="" />
            </div>
            <div class="recommend_03_t">技术开发人员</div>
          </div>
          <div class="recommend_01_t">
            <div>
              <img src="../assets/image/about/card-2.png" alt="" />
            </div>
            <div class="recommend_03_t">发明专利&著作</div>
          </div>
          <div class="recommend_01_t">
            <div>
              <img src="../assets/image/about/card-3.png" alt="" />
            </div>
            <div class="recommend_03_t">覆盖行业</div>
          </div>
          <div class="recommend_01_t">
            <div>
              <img src="../assets/image/about/card-4.png" alt="" />
            </div>
            <div class="recommend_03_t">成功案例</div>
          </div>
        </div>
      </div>
      <div style="margin-top: 0.62rem;">
        <div style="font-size: 0.4rem;font-weight: 400;color: #454545;line-height: 0.47rem;text-align: center;">行业荣誉</div>
        <img style="margin-top: 0.62rem; width: 100%;height: 8.5rem;" src="../assets/image/about/bomtbg.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  data () {
    return {
      devData: [
        {
          h1: "2003 探索",
          src: require("@/assets/image/about/f3_1_1.png"),
          url: require("@/assets/image/about/f3_1.png"),
          year: "2003年",
          t1: "九州通集团全资控股",
          t2: "总部位于湖北省武汉市，注册资本1000万元。",
          t3: "集团物流项目指挥部",
          t4: "“人工时代”",
          t7: "物流体系规划设计及软件技术组织建立;</br>自主研发ERP进销存系统；</br>行业首次引进立体库系统，并在上海、武汉投入运行。</br>",
          img: require("@/assets/image/about/icon-1.png"),
        },
        {
          h1: "2006 起步",
          src: require("@/assets/image/about/f3_2_1.png"),
          url: require("@/assets/image/about/f3_2.png"),
          year: "2006年",
          t1: "物流管理总部",
          t6: "“机械化时代”",
          t7: `堆垛机，传送带，叉车，DPS技术应用。<br/>
            自动研发LMIS1.0 、 LMIS2.0 、 LMIS3.0 、 LMIS4.0，在福建、广东等10司成功实施。`,
          t4: "",
          t5: "",
          img: require("@/assets/image/about/icon-2.png"),
        },
        {
          h1: "2010 发展",
          src: require("@/assets/image/about/f3_3_1.png"),
          url: require("@/assets/image/about/f3_3.png"),
          year: "2010年",
          t1: "技术服务事业部",
          t6: "“自动化时代”",
          t7: `立体库、输送线、分拣机、条码、PDA 、拣货小车技术应用。<br/>
                自主研发LMIS5.0 、 LMIS6.0，自主研发WCS1.0、TMS1.0、TPL1.0，在北京、山东等20余家成功实施，<br/>
                技术对外输出“云南白药”、“武汉远大”等10余家企业。`,
          t4: "",
          t5: "",
          img: require("@/assets/image/about/icon-3.png"),
        },
        {
          h1: "2014 突破",
          src: require("@/assets/image/about/f3_4_1.png"),
          url: require("@/assets/image/about/f3_4.png"),
          year: "2014年",
          t1: "九州通医药集团物流有限公司",
          t6: "“智能化时代”",
          t7: `面向服务的SOA技术架构升级，TDP技术开发平台面世；</br>
          LMIS.NET自主研发，全国实施物流管理模式由单体运营转向集中管理；</br>
          穿梭车、AGV、立体库、输送分拣机、图像识别、GPS/GIS技术的广泛应用；</br>
          大规模技术对外输出，天津金耀、云南白药等70余家企业完美交付。</br>`,
          t4: "",
          t5: "",
          img: require("@/assets/image/about/icon-4.png"),
        },
        {
          h1: "2018 飞跃",
          src: require("@/assets/image/about/f3_4_1.png"),
          url: require("@/assets/image/about/f3_4.png"),
          year: "2014年",
          t1: "九州通医药集团物流有限公司",
          t6: "“智能化时代”",
          t7: `互联网、大数据技术架构升级，云智集成开发平台面世；</br>
          OWTBQOE物流全链路软件产品研发实施，物流管理模式发展为平台化、网络化、智能化；</br>
          自主研发研发穿梭车，提升机，多样式AGV，P&D智能物流设备；</br>
          为医药生产、商业、连锁企业、物流企业近300余家企业提供数智物流一体化解决方案。</br>`,
          t4: "",
          t5: "",
          img: require("@/assets/image/about/icon-5.png"),
        }
      ],
      devYear: ["2003年", "2006年", "2010年", "2014年", "2018年"],
      currentIndex: 0,
      currentPos: [
        {
          src: require("@/assets/image/about/f3_p1.png"),
          width: "3.616rem",
          offset: "0rem",
        },
        {
          src: require("@/assets/image/about/f3_p2.png"),
          width: "6.59rem",
          offset: "3rem",
        },
        {
          src: require("@/assets/image/about/f3_p3.png"),
          width: "9.54rem",
          offset: "6rem",
        },
        {
          src: require("@/assets/image/about/f3_p4.png"),
          width: "12.55rem",
          offset: "9rem",
        },
        {
          src: require("@/assets/image/about/f3_p5.png"),
          width: "15.55rem",
          offset: "12rem",
        },
      ],
    }
  },
  computed: {
    prevBtn () {
      return document.getElementsByClassName("prev")[0]
    },
    nextBtn () {
      return document.getElementsByClassName("next")[0]
    },
    img () {
      return document.getElementById("img_len")
    },
    car () {
      return document.getElementById("car")
    },
  },
  methods: {
    prev () {
      this.prevBtn.className = "prev click"
      this.nextBtn.className = "next"
      this.$refs.carousel.prev()
    },
    next () {
      this.nextBtn.className = "next click"
      this.prevBtn.className = "prev"
      this.$refs.carousel.next()
    },
    changeIndex (index) {
      this.currentIndex = index
      // this.img.style.width = this.currentPos[index].width;
      this.car.style.transform = `translateX(${this.currentPos[index].offset})`
    },
  },
}
</script>

<style lang="scss" scoped>
#about {
  width: 100%;
  font-family: Microsoft YaHei;

  .banner {
    width: 100%;
    height: 4.82rem;
    background: url("../assets/image/about/banner.png") no-repeat;
    background-size: cover;
  }

  .about_title {
    width: 100%;
    height: 0.7rem;
    display: flex;
    justify-items: center;
    align-items: center;

    .logo {
      height: 100%;
      line-height: 0.7rem;
      margin-left: 3.2rem;

      img {
        vertical-align: middle;
      }
    }

    .menu {
      width: 7.49rem;
      height: 0.17rem;
      margin-left: 1.21rem;
      font-size: 0.16rem;
      display: flex;
      justify-content: space-between;

      a {
        font-size: 0.16rem;
        font-weight: bold;
        color: #333333;
      }
    }
  }

  .company_profile {
    width: 12.72rem;
    height: 6.4rem;
    margin-left: 3.2rem;
    padding-top: 1.21rem;

    .company_title {
      width: 100%;
      text-align: center;
      font-size: 0.4rem;

      margin-bottom: 0.59rem;
      font-weight: bold;
      color: #333333;
    }

    .company_text {
      width: 100%;
      font-size: 0.14rem;
      color: #333333;
      line-height: 0.3rem;
    }

    .recommend {
      display: flex;
      height: 1.15rem;
      width: 100%;
      justify-content: space-between;
      margin-top: 0.8rem;

      .recommend_01 {
        display: flex;
        height: 1.15rem;
        flex-direction: column;
        justify-items: center;

        img {
          width: 0.38rem;
          height: 0.38rem;
        }

        .recommend_02 {
          margin-top: 0.24rem;
          margin-bottom: 0.09rem;
          font-size: 0.3rem;
          font-weight: bold;
          color: #1562ec;
          line-height: 0.26rem;
        }

        .recommend_03 {
          font-size: 0.14rem;
          font-weight: 400;
          color: #666666;
          font-family: Source Han Sans CN;
          margin-top: 0.15rem;
        }
      }
    }
  }

  .dev {
    position: relative;
    width: 100%;
    height: 7.54rem;
    background: url("../assets/image/about/history.png") no-repeat;

    h1 {
      position: absolute;
      top: 0.7rem;
      left: 8.66rem;
      font-size: 0.4rem;
      text-align: center;
      font-family: Microsoft YaHei-Semilight, Microsoft YaHei;
      font-weight: normal;
      color: #FFFFFF;
      line-height: 0.64rem;
      z-index: 10;
    }

    .k {
      padding: 1.69rem;
      display: flex;

      .box {
        flex: 1;
        width: 3.5rem;
        margin-left: 1.17rem;

        &:nth-child(1) {
          margin-left: 0;
        }

        .title {
          height: 0.48rem;
          font-size: 0.31rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 0.48rem;
        }

        .conten {
          margin-top: 0.12rem;
          font-size: 0.16rem;
          font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 0.24rem;

        }
      }
    }



    .p_text {
      position: absolute;
      display: flex;
      top: 6.63rem;
      left: 3.24rem;
      z-index: 100;

      .p_bg {
        position: absolute;
        opacity: 0.3;
        height: 0.01rem;
        top: 0.08rem;
        width: 12.4rem;
        background-color: #FFFFFF;
      }

      .t_item {
        width: 0.9rem;

        img {
          width: 0.19rem;
          height: 0.19rem;
          margin-left: 0.28rem;
        }

        div {
          font-size: 0.24rem;
          font-weight: bold;
          color: #4b4b4b;
        }
      }

      .t_item:nth-child(n + 2) {
        margin-left: 2.1rem;
      }
    }


    #car {
      position: absolute;
      transition: all 0.4s;
      top: 6.03rem;
      left: 3.01rem;
      width: 1.24rem;
      height: 0.7rem;
      // border: 0.01rem solid black;
      z-index: 11;

      img {
        height: 0.7rem;
        width: 1.3rem;
      }
    }
  }
}

.tn {
  background: url(../assets/image/about/about-img.png);
  background-size: cover;

  .tn-1 {
    /* margin-top: 0.7rem; */
    text-align: center;
    height: 0.56rem;
    font-size: 0.4rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #101010;
    line-height: 0.47rem;
  }

  .recommend-t {
    margin-top: 0.51rem;

    .recommend_01_t {
      display: flex;
      flex-direction: column;
      width: 4rem;
      position: relative;

      img {
        position: relative;
        left: 50%;
        margin-left: -0.45rem;
        width: 0.9rem;
        height: 0.9rem;
      }

      .recommend_03_t {
        margin-top: 0.4rem;
        text-align: center;
      }
    }
  }
}

.click {
  background: #1562ec !important;

  span {
    color: white !important;
  }
}

.color {
  color: #FFFFFF !important;
}
</style>
